export const SET_SEASON = 'SET_SEASON';
export const SET_STAGE = 'SET_STAGE';
export const SET_WEEK = 'SET_WEEK';
export const RESET = 'RESET';
export const SET_URL = 'SET_URL';
export const HIDE_DROPDOWNS = 'HIDE_DROPDOWNS';
export const DISPLAY_SEASON_DROPDOWN = 'DISPLAY_SEASON_DROPDOWN';
export const DISPLAY_STAGE_DROPDOWN = 'DISPLAY_STAGE_DROPDOWN';
export const DISPLAY_WEEK_DROPDOWN = 'DISPLAY_WEEK_DROPDOWN';

export const FilterReducer = (state, action) => {
  switch (action.type) {
    case SET_SEASON:
      return {
        ...state,
        season: action.payload,
        stage: null,
        week: null,
        displaySeasonOptions: false,
        displayStageOptions: false,
        displayWeekOptions: false,
      };
    case SET_STAGE:
      return {
        ...state,
        stage: action.payload,
        week: null,
        displayStageOptions: false,
        displaySeasonOptions: false,
        displayWeekOptions: false,
      };
    case SET_WEEK:
      return {
        ...state,
        week: action.payload,
        displaySeasonOptions: false,
        displayStageOptions: false,
        displayWeekOptions: false,
      };
    case RESET:
      return {
        ...state,
        season: null,
        stage: null,
        week: null,
      };
    case SET_URL:
      return { ...state, url: action.payload };
    case HIDE_DROPDOWNS:
      return {
        ...state,
        displaySeasonOptions: false,
        displayStageOptions: false,
        displayWeekOptions: false,
      };
    case DISPLAY_SEASON_DROPDOWN:
      return {
        ...state,
        displaySeasonOptions: action.payload,
        displayStageOptions: false,
        displayWeekOptions: false,
      };
    case DISPLAY_STAGE_DROPDOWN:
      return {
        ...state,
        displayStageOptions: action.payload,
        displaySeasonOptions: false,
        displayWeekOptions: false,
      };
    case DISPLAY_WEEK_DROPDOWN:
      return {
        ...state,
        displayWeekOptions: action.payload,
        displaySeasonOptions: false,
        displayStageOptions: false,
      };
    default:
      return { state };
  }
};
