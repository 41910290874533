import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CSSTransition } from 'react-transition-group';

import { DISPLAY_SEASON_DROPDOWN, SET_SEASON } from './FilterReducer';
import FilterContext from './FilterContext';

import {
  StyledFilterWrapper,
  StyledButton,
  StyledDropdown,
  StyledFilterButton,
} from './DropdownElements';
import DownIcon from '../icons/DownIcon';

const SeasonDropdown = () => {
  const { state, dispatch } = useContext(FilterContext);
  const data = useStaticQuery(graphql`
    query SeasonsQuery {
      allMyNodeTypeSeason {
        edges {
          node {
            id
            season
          }
        }
      }
    }
  `);

  return (
    <StyledFilterWrapper>
      <StyledButton
        type="button"
        data-toggle="dropdown"
        id="season-filter-toggle"
        aria-haspopup="true"
        aria-expanded={state.displaySeasonOptions}
        onClick={() => {
          dispatch({
            type: DISPLAY_SEASON_DROPDOWN,
            payload: state.displaySeasonOptions ? false : true,
          });
        }}
      >
        {state.season ? `Season ${state.season}` : 'All seasons'} <DownIcon />
      </StyledButton>
      <CSSTransition
        in={state.displaySeasonOptions}
        mountOnEnter
        timeout={{
          enter: 100,
          exit: 100,
        }}
        unmountOnExit
        classNames="dropdown"
      >
        <StyledDropdown
          id="season-filter-dropdown"
          aria-labelledby="season-filter-toggle"
        >
          <ul>
            <li>
              <StyledFilterButton
                onClick={() => {
                  dispatch({ type: SET_SEASON, payload: null });
                }}
              >
                All seasons
              </StyledFilterButton>
            </li>

            {data &&
              data.allMyNodeTypeSeason.edges.map((season) => (
                <li key={`season-${season.node.id}`}>
                  <StyledFilterButton
                    onClick={() => {
                      dispatch({
                        type: SET_SEASON,
                        payload: parseInt(season.node.season),
                      });
                    }}
                  >
                    Season {season.node.season}
                  </StyledFilterButton>
                </li>
              ))}
          </ul>
        </StyledDropdown>
      </CSSTransition>
    </StyledFilterWrapper>
  );
};

export default SeasonDropdown;
