export const stageTranslator = (stage) => {
  if (stage === 5) {
    return 'Grand finals';
  } else if (stage === 6) {
    return 'All Stars';
  } else {
    return `Stage ${stage}`;
  }
};

export const weekTranslator = (week) => {
  if (week === 6) {
    return 'Stage playoffs';
  } else {
    return `Week ${week}`;
  }
};
