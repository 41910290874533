import React from 'react';
import styled from 'styled-components';

import media from '../styles/media';

const StyledHeading = styled.h2`
  text-align: center;
  font-size: 2rem;

  ${media.bigPhone`
    font-size: 2.5rem;
  `}
`;

const NoClips = ({ title }) => {
  return <StyledHeading>{title}</StyledHeading>;
};

export default NoClips;
