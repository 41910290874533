import React, { useState } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';

import CloseIcon from './icons/CloseIcon';
import Clip from './Clip';

import media from '../styles/media';

ReactModal.setAppElement('#___gatsby');

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: max-content;
  grid-gap: 30px;
`;

const Styled16x9 = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: black;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    border: 0;
    border-radius: 4px;
  }
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  padding: 10px;
  align-self: flex-end;
  cursor: pointer;

  > svg {
    color: white;
    height: 30px;

    ${media.tablet`
      height: 40px;
    `}
  }
`;

const ClipGrid = ({ clips }) => {
  const [showModal, setShowModal] = useState(false);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [clipTitle, setClipTitle] = useState(null);

  return (
    <>
      <StyledGrid>
        {clips.map((clip) => (
          <Clip
            setShowModal={setShowModal}
            setEmbedUrl={setEmbedUrl}
            setClipTitle={setClipTitle}
            key={clip.node.id}
            id={clip.node.id}
            embedURL={clip.node.embed_url}
            image={clip.node.thumbnail}
            title={clip.node.title}
            creator={clip.node.creator}
            views={clip.node.views}
          />
        ))}
      </StyledGrid>

      <ReactModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Example Modal"
        shouldFocusAfterRender={true}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        role="dialog"
        className="modal-inner"
        overlayClassName="modal-overlay"
        closeTimeoutMS={150}
      >
        <CloseButton onClick={() => setShowModal(false)}>
          <CloseIcon />
        </CloseButton>
        <Styled16x9>
          <iframe
            title={clipTitle}
            src={embedUrl}
            frameBorder="0"
            scrolling="no"
            allowFullScreen={true}
            muted={false}
          />
        </Styled16x9>
      </ReactModal>
    </>
  );
};

export default ClipGrid;
