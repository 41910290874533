import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CSSTransition } from 'react-transition-group';

import { DISPLAY_STAGE_DROPDOWN, SET_STAGE } from './FilterReducer';
import FilterContext from './FilterContext';

import {
  StyledFilterWrapper,
  StyledButton,
  StyledDropdown,
  StyledFilterButton,
} from './DropdownElements';
import DownIcon from '../icons/DownIcon';

import { stageTranslator } from '../../lib/translators';

const StageDropdown = () => {
  const { state, dispatch } = useContext(FilterContext);
  const data = useStaticQuery(graphql`
    query StagesQuery {
      allMyNodeTypeStage {
        nodes {
          id
          season
          stage
        }
      }
    }
  `);

  return (
    <StyledFilterWrapper>
      <StyledButton
        disabled={!state.season}
        type="button"
        data-toggle="dropdown"
        id="stage-filter-toggle"
        aria-haspopup="true"
        aria-expanded={state.displayStageOptions}
        onClick={() => {
          dispatch({
            type: DISPLAY_STAGE_DROPDOWN,
            payload: true,
          });
        }}
      >
        {state.stage ? stageTranslator(state.stage) : 'All stages'}
        <DownIcon />
      </StyledButton>
      <CSSTransition
        in={state.displayStageOptions}
        mountOnEnter
        timeout={{
          enter: 100,
          exit: 100,
        }}
        unmountOnExit
        classNames="dropdown"
      >
        <StyledDropdown
          id="stage-filter-dropdown"
          aria-labelledby="stage-filter-toggle"
        >
          <ul>
            <li key={`season=${state.season}-stage-any`}>
              <StyledFilterButton
                onClick={() => {
                  dispatch({ type: SET_STAGE, payload: null });
                }}
              >
                All stages
              </StyledFilterButton>
            </li>

            {data &&
              data.allMyNodeTypeStage.nodes
                .filter((stage) => stage.season === state.season)
                .map((stage) => (
                  <li key={stage.id}>
                    <StyledFilterButton
                      onClick={() => {
                        dispatch({
                          type: SET_STAGE,
                          payload: parseInt(stage.stage),
                        });
                      }}
                    >
                      {stageTranslator(stage.stage)}
                    </StyledFilterButton>
                  </li>
                ))}
          </ul>
        </StyledDropdown>
      </CSSTransition>
    </StyledFilterWrapper>
  );
};

export default StageDropdown;
