import React from 'react';
import styled from 'styled-components';

import LoadingImage from '../components/LoadingImage';
import UserIcon from '../components/icons/UserIcon';
import ViewsIcon from '../components/icons/ViewsIcon';
import PlayIcon from '../components/icons/PlayIcon';

import media from '../styles/media';

const StyledPlayIconContainer = styled.div`
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.35);
  opacity: 0;

  > svg {
    fill: white;
    height: 50px;
    fill: ${(props) => props.theme.pizazz};
  }
`;

const StyledClip = styled.article`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.bs};
  transition: all 0.2s ease-in-out;
  position: relative;
  backface-visibility: hidden;
  transform: translateZ(0);

  &:hover {
    ${media.tablet`
      transform: translate(0, -3px);

      ${StyledPlayIconContainer} {
        opacity: 1;
      }
    `}
  }
`;

const StyledInfo = styled.section`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  transition: all 0.2s ease-in-out;
`;

const StyledClipTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  line-height: 1.1;
  text-align: left;
`;

const StyledDetails = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 15px 20px;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;

    svg {
      height: 15px;
      margin-right: 7px;
      flex-shrink: 0;
    }

    a {
      color: ${(props) => props.theme.black};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: all 0.15s ease-in-out;

      &:hover {
        color: ${(props) => props.theme.tenn};
      }
    }
  }
`;

const StyledImageButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    ${StyledPlayIconContainer} {
      opacity: 1;
    }
  }
`;

const StyledTitleButton = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  display: block;
  padding: 0;
  width: 100%;
`;

const StyledImageContainer = styled.section`
  overflow: hidden;
  padding-top: calc(272 / 480 * 100%);
  background-color: ${(props) => props.theme.mediumGrey};
  position: relative;
  border-radius: 5px 5px 0 0;
`;

const StyledCreator = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledViews = styled.div`
  flex-shrink: 0;
`;

const StyledInfoHeader = styled.header`
  padding: 20px;
`;

const Clip = ({
  image,
  id,
  title,
  views,
  creator,
  embedURL,
  selectClip,
  navigate,
  setShowModal,
  setEmbedUrl,
  setClipTitle,
}) => {
  return (
    <>
      <StyledClip>
        <StyledImageContainer>
          <StyledImageButton
            aria-label={`Play video ${title}`}
            onClick={() => {
              setShowModal(true);
              setEmbedUrl(embedURL);
              setClipTitle(title);
            }}
          >
            <LoadingImage
              imageSrc={`${
                process.env.NODE_ENV === 'production'
                  ? 'https://res.cloudinary.com/dnbonj4hv/image/fetch/f_auto/q_70/'
                  : ''
              }${image}`}
              alt={title}
            />
            <StyledPlayIconContainer>
              <PlayIcon />
            </StyledPlayIconContainer>
          </StyledImageButton>
        </StyledImageContainer>

        <StyledInfo>
          <StyledInfoHeader>
            <StyledTitleButton
              aria-label={`Play video ${title}`}
              onClick={() => {
                setShowModal(true);
                setEmbedUrl(embedURL);
                setClipTitle(title);
              }}
            >
              <StyledClipTitle>{title}</StyledClipTitle>
            </StyledTitleButton>
          </StyledInfoHeader>
          <StyledDetails>
            <StyledCreator>
              <UserIcon />
              <a href={`https://twitch.tv/${creator}`}>{creator}</a>
            </StyledCreator>
            <StyledViews>
              <ViewsIcon />
              <span>{views.toLocaleString()}</span>
            </StyledViews>
          </StyledDetails>
        </StyledInfo>
      </StyledClip>
    </>
  );
};

export default Clip;
