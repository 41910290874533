import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import ClapperboardIcon from './icons/ClapperboardIcon';

import media from '../styles/media';

const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.span`
  padding: 8px 0 0 10px;
  font-family: Teko;
  font-size: 2rem;
  display: block;
  width: 130px;

  ${media.bigPhone`
    font-size: 2.75rem;
  `}
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${(props) => props.theme.black};
  min-height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;

  ${media.tablet`
    padding-left: 0;
  `}

  ${media.bigPhone`
    height: 72px;
    padding-left: 20px;
  `};
`;

const StyledIcon = styled(ClapperboardIcon)`
  height: 30px;

  ${media.bigPhone`
    height: 40px;
  `}
`;

const Logo = () => {
  return (
    <StyledLink to="/">
      <StyledLogo>
        <StyledIcon />
        <StyledTitle>OWLClips</StyledTitle>
      </StyledLogo>
    </StyledLink>
  );
};

export default Logo;
