import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const progress = keyframes`
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
`;

const StyledLoadingImage = styled.div`
  background-color: ${(props) => props.theme.mediumGrey};
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.666666666%;
`;

const StyledSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 56.666666666%;
  z-index: 10;
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.mediumGrey},
    ${(props) => props.theme.lightGrey},
    ${(props) => props.theme.mediumGrey}
  );
  animation: ${progress} 1s ease-in-out infinite;
`;

const StyledImage = styled.img`
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 100;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
`;

const LoadingImage = ({ imageSrc, alt }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoaded(true);
    };

    img.src = imageSrc;
  }, [imageSrc]);

  return (
    <StyledLoadingImage loaded={loaded ? true : false}>
      {!loaded && <StyledSlider />}
      <StyledImage loaded={loaded ? true : false} src={imageSrc} alt={alt} />
    </StyledLoadingImage>
  );
};

export default LoadingImage;
