import React, { useRef, useEffect, useReducer } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { FilterReducer, HIDE_DROPDOWNS, SET_URL, RESET } from './FilterReducer';
import FilterContext from './FilterContext';

import SeasonDropdown from './SeasonDropdown';
import StageDropdown from './StageDropdown';
import WeekDropdown from './WeekDropdown';
import ArrowRightIcon from '../icons/ArrowRightIcon';

import useOnClickOutside from '../../hooks/useOnClickOutside';

import media from '../../styles/media';

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-self: stretch;
  border-top: 1px solid rgba(0, 0, 0, 0.03);
  margin-top: 0;
  width: 100%;

  ${media.tablet`
    flex-direction: row;
    height: 72px;
  `}

  ${media.desktop`
    margin-top: 0;
    border-top: none;
    width: 75%;
  `}
`;

const StyledFilterWrapper = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);

  ${media.tablet`
    width: 25%;
    border-left: 1px solid rgba(0, 0, 0, 0.03);
    border-bottom: none;
  `}

  ${media.desktop`
    width: auto;
  `}

  &:last-of-type {
    border-right: none;
    border-bottom: none;
  }
`;

const StyledSubmitButtonWrapper = styled(StyledFilterWrapper)`
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.025);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 10px;
    height: 20px !important;
  }

  ${media.tablet`
    width: 25%;
  `}

  ${media.desktop`
    height: 72px;
    flex-grow: 1;
  `}
`;

const StyledSubmitFilterButton = styled(Link)`
  color: ${(props) => props.theme.black};
  min-width: 0;
  justify-content: center;
  text-transform: uppercase;
  font-size: 1rem;
  border: 1px solid #ffcd94;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  width: auto;
  padding: 15px 20px;
  background-color: hsl(214.7, 30.4%, 49%);
  border: none;
  color: white;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${(props) => props.theme.bs};

  > svg {
    margin-left: 20px;
    height: 20px !important;
    transition: transform 0.2s ease-in-out;
  }

  ${media.tablet`
    width: 100%;
    margin: 0;
  `}

  ${media.desktop`
    min-width: 0px;
    padding: 10px 20px;
  `}

  &:hover {
    background-color: hsl(214.7, 30.4%, 39%);

    svg {
      transform: translateX(5px);
    }
  }
`;

const Filter = (props) => {
  const [state, dispatch] = useReducer(FilterReducer, {
    season: props.season ? parseInt(props.season) : null,
    stage: props.stage ? parseInt(props.stage) : null,
    week: props.week ? parseInt(props.week) : null,
    displaySeasonOptions: false,
    displayStageOptions: false,
    displayWeekOptions: false,
    url: '/',
    now: new Date(),
  });

  const filterRef = useRef();

  useOnClickOutside(filterRef, () => {
    if (
      props.width > 768 &&
      (state.displaySeasonOptions ||
        state.displayStageOptions ||
        state.displayWeekOptions)
    ) {
      dispatch({ type: HIDE_DROPDOWNS });
    }
  });

  useEffect(() => {
    const { season, stage, week } = props;

    if (!season && !stage && !week) {
      dispatch({ type: RESET });
    }
  }, [props, props.season, props.stage, props.week]);

  useEffect(() => {
    let url = '/';

    if (state.season) {
      url += `season-${state.season}`;
    }

    if (state.stage) {
      url += `/${
        state.stage < 5
          ? `stage-${state.stage}`
          : state.stage === 5
          ? 'grand-finals'
          : 'all-stars'
      }`;
    }

    if (state.week) {
      url += `/${state.week < 6 ? `week-${state.week}` : 'stage-playoffs'}`;
    }

    dispatch({ type: SET_URL, payload: url });
  }, [state.season, state.stage, state.week]);

  useEffect(() => {
    dispatch({ type: HIDE_DROPDOWNS });
  }, [props.path]);

  return (
    <FilterContext.Provider value={{ state, dispatch }}>
      <StyledNav ref={filterRef}>
        <SeasonDropdown />
        <StageDropdown />
        <WeekDropdown />

        <StyledSubmitButtonWrapper>
          <StyledSubmitFilterButton to={state.url}>
            <span>Filter clips</span>
            <ArrowRightIcon />
          </StyledSubmitFilterButton>
        </StyledSubmitButtonWrapper>
      </StyledNav>
    </FilterContext.Provider>
  );
};

Filter.getInitialProps = async ({ query }) => {
  const { season, stage, week } = query;

  const seasonNum = season.replace('season-', '');
  const stageNum = stage.replace('stage-', '');
  const weekNum = week.replace('week-', '');

  return { seasonNum, stageNum, weekNum };
};

export default Filter;
