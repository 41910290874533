import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import PageTitle from '../components/PageTitle';
import SEO from '../components/seo';

import media from '../styles/media';

const StyledTextWrapper = styled.div`
  padding: 10px 0 40px 0;
`;

const StyledCentered = styled.p`
  text-align: center;
  font-size: 1rem;

  ${media.tablet`
    font-size: 1.2rem;
  `}
`;

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <PageTitle title="Page not found" />
    <StyledTextWrapper>
      <StyledCentered>
        Sorry, we couldn't find the page that you're looking for.
      </StyledCentered>
      <StyledCentered>
        You can try navigating by the filter bar the top, or by going{' '}
        <Link to="/">home</Link>.
      </StyledCentered>
    </StyledTextWrapper>
  </>
);

export default NotFoundPage;
