// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-season-js": () => import("/opt/build/repo/src/pages/season.js" /* webpackChunkName: "component---src-pages-season-js" */),
  "component---src-pages-stage-js": () => import("/opt/build/repo/src/pages/stage.js" /* webpackChunkName: "component---src-pages-stage-js" */),
  "component---src-pages-week-js": () => import("/opt/build/repo/src/pages/week.js" /* webpackChunkName: "component---src-pages-week-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

