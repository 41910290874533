import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { TransitionGroup, Transition } from 'react-transition-group';

import '../styles/global.css';
import '../styles/fonts.css';
import Header from './Header';
import Footer from './Footer';

import media from '../styles/media';

const theme = {
  black: '#393939',
  seashell: '#f1f1f1',
  tenn: '#c75a00',
  pizazz: '#ff8900',
  mediumGrey: '#cccccc',
  lightGrey: '#dddddd',
  maxWidth: '1200px',
  bs: '0px 2px 4px rgba(0,0,0,0.18)',
};

const StyledPage = styled.div`
  background: ${theme.seashell};
  min-height: 100vh;
  color: ${(props) => props.theme.black};
  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  width: 100%;
  margin: 0 auto;
  padding: 15px;

  ${media.tablet`
    padding: 30px;
  `};
`;

const StyledMain = styled.main`
  transition: all 0.2s ease-in-out;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  transform: translateY(${({ state }) => (state === 'entered' ? '0' : '20px')});
  min-height: 75vh;
`;

const layout = ({ location, pageContext, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <StyledPage>
          <StyledContainer>
            <Header
              season={
                pageContext && pageContext.season ? pageContext.season : null
              }
              stage={
                pageContext && pageContext.stage ? pageContext.stage : null
              }
              week={pageContext && pageContext.week ? pageContext.week : null}
              path={location.pathname}
            />
            <TransitionGroup component={null}>
              <Transition
                appear
                key={`${pageContext && pageContext.season}-${pageContext &&
                  pageContext.stage}-${pageContext && pageContext.week}`}
                timeout={0}
              >
                {(state) => (
                  <StyledMain state={state} id="main-content">
                    {children}
                  </StyledMain>
                )}
              </Transition>
            </TransitionGroup>
            <Footer />
          </StyledContainer>
        </StyledPage>
      </>
    </ThemeProvider>
  );
};

export default layout;
