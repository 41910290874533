import React from 'react';
import styled from 'styled-components';
import media from '../styles/media';

const StyledPageTitle = styled.h1`
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  line-height: 1;
  margin: 75px 0 30px;

  ${media.bigPhone`
    margin: 100px 0 30px;
    font-size: 4rem;
  `};

  ${media.tablet`
    font-size: 4rem;
  `};

  ${media.desktop`
    font-size: 5rem;
  `}
`;

const PageTitle = ({ title, className }) => {
  return <StyledPageTitle className={className}>{title}</StyledPageTitle>;
};

export default PageTitle;
