import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={
        title
          ? `${title} - ${site.siteMetadata.title} - The best moments from the Overwatch League`
          : `${site.siteMetadata.title} - The best moments from the Overwatch League`
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: 'OWLClips',
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image:type`,
          content: `image/jpeg`,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: '628',
        },
        {
          property: `og:image`,
          content: `https://owlclips.com/preview.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: 'OWLClips',
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `https://owlclips.com/preview.jpg`,
        },
      ].concat(meta)}
    >
      <link rel="preconnect" href="https://clips.twitch.tv/" crossorigin />
      <link rel="dns-prefetch" href="https://clips.twitch.tv/" />
      <link rel="preconnect" href="https://player.twitch.tv/" crossorigin />
      <link rel="dns-prefetch" href="https://player.twitch.tv/" />
      <link rel="preconnect" href="https://gql.twitch.tv/" crossorigin />
      <link rel="dns-prefetch" href="https://gql.twitch.tv/" />
      <link rel="preconnect" href="https://api.twitch.tv/" crossorigin />
      <link rel="dns-prefetch" href="https://api.twitch.tv/" />
      <link
        rel="preconnect"
        href="https://clips-media-assets2.twitch.tv/"
        crossorigin
      />
      <link rel="dns-prefetch" href="https://clips-media-assets2.twitch.tv/" />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
