import React from 'react';
import styled from 'styled-components';

import TwitterIcon from './icons/TwitterIcon';

const StyledFooter = styled.footer`
  margin-top: 30px;
  padding: 20px 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledCopyright = styled.p`
  font-size: 12px;
  text-align: left;
  line-height: 1.5;
  color: rgb(110, 110, 110);

  > a {
    color: rgb(110, 110, 110);
  }
`;

const StyledTwitterIcon = styled(TwitterIcon)`
  height: 25px;
  width: 25px;
  transition: color 0.2s ease-in-out;
`;

const StyledTwitterLink = styled.a`
  padding: 8px;
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  margin-right: -8px;

  &:hover,
  &:focus,
  &:active {
    color: rgba(29, 161, 242, 1);

    ${StyledTwitterIcon} {
      color: rgba(29, 161, 242, 1);
    }
  }

  ${StyledTwitterIcon} {
    color: rgb(110, 110, 110);
  }
`;

const StyledSmall = styled.small`
  display: block;
  padding-top: 5px;
  margin: 0;
  font-size: 12px;
`;

const Footer = () => {
  return (
    <>
      <StyledFooter>
        <StyledCopyright>
          &copy; {new Date().getFullYear()}{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/danielgittings"
          >
            Daniel Gittings
          </a>
          . All rights reserved.
          <StyledSmall>OVERWATCH © 2019 BLIZZARD.</StyledSmall>
        </StyledCopyright>

        <StyledTwitterLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/owlclips"
          aria-label="Follow OWLClips on Twitter"
        >
          <StyledTwitterIcon />
        </StyledTwitterLink>
      </StyledFooter>

      {process.env.NODE_ENV === 'production' && (
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(f, a, t, h, o, m){
              a[h]=a[h]||function(){
                (a[h].q=a[h].q||[]).push(arguments)
              };
              o=f.createElement('script'),
              m=f.getElementsByTagName('script')[0];
              o.async=1; o.src=t; o.id='fathom-script';
              m.parentNode.insertBefore(o,m)
            })(document, window, '//stats.danielgittings.com/tracker.js', 'fathom');
            fathom('set', 'siteId', 'VBHEY');
            fathom('trackPageview');`,
          }}
        />
      )}

      <div
        dangerouslySetInnerHTML={{
          __html: `<!-- Icons by Font Awesome. License: https://fontawesome.com/license/free -->`,
        }}
      />
    </>
  );
};

export default Footer;
