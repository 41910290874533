import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import PageTitle from '../components/PageTitle';
import ClipGrid from '../components/ClipGrid';
import NoClips from '../components/NoClips';

export default ({ pageContext, data }) => {
  const clips = data.allMyNodeTypeClip.edges;

  return (
    <>
      <SEO title={pageContext.title} />
      <PageTitle title={pageContext.title} />

      {clips.length > 0 ? (
        <ClipGrid clips={clips} />
      ) : (
        <NoClips title="We don't have any clips for this week yet. Please check back later." />
      )}
    </>
  );
};

export const query = graphql`
  query WeekQuery($season: Int, $stage: Int, $week: Int) {
    allMyNodeTypeClip(
      filter: {
        season: { eq: $season }
        stage: { eq: $stage }
        week: { eq: $week }
      }
      limit: 12
      sort: { fields: views, order: DESC }
    ) {
      edges {
        node {
          id
          creator
          embed_url
          season
          stage
          thumbnail
          title
          views
          week
        }
      }
    }
  }
`;
